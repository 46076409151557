export const PathName = {
  Login: '/',
  Dashboard: '/dashboard',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password',
  Events: '/events',
  Places: '/places',
  AddPlace: '/add-place',
  Organizations: '/organizations',
  AddOrganization: '/add-organization',
  People: '/people',
  AddPerson: '/add-person',
  Taxonomies: '/taxonomies',
  AddTaxonomy: '/add-taxonomy',
  AddTaxonomySelectType: '/select-taxonomy-type',
  Settings: '/settings',
  UserManagement: '/user-management',
  AddEvent: '/add-event',
  AcceptInvitation: '/accept',
  Join: '/join',
  User: '/user',
  Profile: '/profile',
  Search: '/search',
  AddUser: '/add-user',
  NotFound: '/*',
};
